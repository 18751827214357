import SmoothScroll from "smooth-scroll";

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true
});

//Scroll up arrow
const scrollUp = document.getElementById("up");
if (scrollUp) {
    scrollUp.addEventListener("click", function() {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            document.getElementById("up").classList.add("active");
        } else {
            document.getElementById("up").classList.remove("active");
        }
    } else {
        document.getElementById("up").classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onload = function() {
    scrollUpToggle();
};

window.onscroll = function() {
    scrollUpToggle();
};
